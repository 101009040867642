<template lang="pug">
.chat-app(:class="{'chat-app--with-product': productData}")
  .chat-app__header
    img.chat-app__header-back(v-if="chatId !== 'ALL'" :src="require('~/assets/img/icons/angle-left.svg')" @click="setChatId('ALL')" alt="angle left")
    .chat-app__header-title
      | {{ chatTitle }}
      template(v-if="chatId !== 'ALL' && chatWithOnlineText")
        .chat-app__header-title-status(:class="`chat-app__header-title-status__${chatWithOnlineText}`") {{ $t(chatWithOnlineText) }}
    .chat-app__header-icons
      .chat-app__header-menu-item.chat-app__header-menu-item--success(v-if="!socialLink && chatId !== 'ALL' && !isClient"  @click="redirectSocialLink(chat)" :key="'link'")
        img.chat-app__header-menu-item-icon(:src="require(`~/assets/img/icons/telegram.svg`)" alt="tg")
        | {{ $t('buttonLink') }}
      .chat-app__header-menu-item.chat-app__header-menu-item--success(v-if="socialLink && chatId !== 'ALL' && !isClient" @click="redirectSocialLink(chat)" :key="'unlink'")
        img.chat-app__header-menu-item-icon(:src="require(`~/assets/img/icons/telegram.svg`)" alt="tg")
        | {{ $t('buttonUnlink') }}
      .chat-app__header-dots(v-if="isOrderChat" @click="switchMenu")
        img(:src="require('~/assets/img/icons/dots.svg')" alt="dots")
      .chat-app__header-close(@click="close")
        img(:src="require('~/assets/img/icons/close.svg')" alt="close")
    .chat-app__header-menu(v-if="menuIsOpen")
      .chat-app__header-menu-item.chat-app__header-menu-item--success(v-if="isClient && !orderNotPaid() && orderNotCompleted()" @click="completeOrder(orderId)")
        img.chat-app__header-menu-item-icon(:src="require(`~/assets/img/icons/checkmark-green.svg`)" alt="checkmark")
        | {{ $t('completeOrder') }}
      .chat-app__header-menu-item.chat-app__header-menu-item--success(v-if="isClient && orderCompletedButNotReviewed()" @click="leaveFeedback(orderId, orderById(orderId)?.seller_id)")
        img.chat-app__header-menu-item-icon(:src="require(`~/assets/img/icons/star.svg`)" alt="star")
        | {{ $t('reviewOrder') }}
      .chat-app__header-menu-item.chat-app__header-menu-item--red(v-if="!isClient && orderNotCompleted()" @click="cancelOrder(orderId)")
        img.chat-app__header-menu-item-icon(:src="require(`~/assets/img/icons/close.svg`)" alt="close")
        | {{ $t('cancelOrder') }}
  .chat-app__chats(v-if="chatId === 'ALL'" key="chatsALL")
    .chat-app__chats-chat.chat-app__chats-chat--support(v-support-button)
      .chat-app__chats-chat-cover.chat-app__chats-chat-cover--support
        img.chat-app__chats-chat-cover-img.chat-app__chats-chat-cover-img--support(:src="require(`~/assets/img/VVV-logo.svg`)" alt="logo")
      span.chat-app__chats-chat-title {{ $t("support") }}
    .chat-app__chats-chat(v-for="chat in chats" :key="chat.chat_id" @click="openChat(chat)")
      .chat-app__chats-chat-cover
        img.chat-app__chats-chat-cover-img(v-if="chatAvatar(chat)" :src="chatAvatar(chat)" alt="avatar" v-lazy-load)
        img.chat-app__chats-chat-cover-img(v-else :src="require(`~/assets/img/icons/user.svg`)" alt="user")
      span.chat-app__chats-chat-title {{ chatTitleByChat(chat) }}
      .chat-app__chats-chat-counter(v-if="chat.new_messages") {{ chat.new_messages }}

  .chat-app__messages(v-else :key="`chat_${chatId}`")
    .chat-app__messages-body(ref="messagesBody")
      div(v-for="(message, i) in messages" :key="message.id")
        .chat-app__message(:class="{'chat-app__message--my': isMyMessage(message)}" :id="messages.length - 1 === i ? 'last-message' : undefined")
          template(v-if="isOrderMessage(message)")
            NuxtLink.chat-app__order(:to="localeLocation(`/marketplace/order/${messageOrderId(message)}`)" @click.native="close")
              span.chat-app__order-number {{ $t('order') }} {{ messageOrderId(message) }}
              //span.chat-app__order-title {{ messageProductTitle(messageOrderId(message)) }}
              //span.chat-app__order-amount {{ $t('nPcs', { n: messageProductAmount(messageOrderId(message)) }) }}
            .chat-app__order(v-for="item in orderItems(message)")
              span.chat-app__order-title {{ item.title }}
              span.chat-app__order-amount {{ $t('nPcs', { n: item.amount }) }}
          .chat-app__message-user(:class="{'chat-app__message-user--my': isMyMessage(message.text), 'chat-app__message-user--system': message.account_id === 0}")
            | {{ message.user_name }}
            .chat-app__message-user-label(:class="{'chat-app__message-user-label--system': message.account_id === 0}")
              | {{ labelText(message) }}
          .chat-app__message-img(v-if="isImgFile(message.text)")
            img(:src="fileUrl(message.text)" :alt="fileName(message.text)")
          .chat-app__message-file(v-else-if="isFile(message.text)")
            a(:href="fileUrl(message.text)") {{ fileName(message.text) }}
          .chat-app__message-text(v-else)
            span(v-html="messageText(message)")
            NuxtLink.chat-app__message-info(v-if="messageProductId(message)" :to="localeLocation(`/marketplace/product/${messageProductId(message)}`)")
              | {{ $t("sentFromProductPage") }}
              span.chat-app__message-info-item {{ messageProductName(message) }}
          .chat-app__message-buttons(v-if="showButtons(message) && messageButtons(message)?.length")
            Button.chat-app__message-button(v-for="button in messageButtons(message)" :key="button.id" is-primary is-small @click="button.action")
              | {{ button.text }}
            Button(is-red v-support-button) {{ $t('supportCenter') }}
          .chat-app__message-created {{ created(message) }}
  .chat-app__send-group(v-if="chatId !== 'ALL'")
    .chat-app__send
      FormInput.card-form__field-input(
        v-model="text"
        :placeholder="$t('textMessage')"
        icon="send"
        icon-right
        @iconClick="sendMessage"
        @keyup.enter.native="sendMessage"
      )
    ImageUpload(v-model="uploadedFile" short @click="chatClickAttachEvent")
      template(v-slot:button)
        img.chat-app__upload-icon(:src="require('~/assets/img/icons/link.svg')" alt="link")
  .chat-app__product(v-if="productData")
    CheckoutShort(
      v-model="payMethod"
      :pay-methods="payMethodsToSelect"
      :product="product"
      :final-price-with-commission="finalPriceWithCommission"
      :total-commission="totalCommission"
      :commission-text="commissionText"
      :show-button="showButton"
      :is-seller="false"
      :order-amount="productAmount"
      @buy="pay"
    )
</template>

<script>
import io from 'socket.io-client'
import { mapActions, mapMutations, mapState } from 'vuex'
import BaseSelect from '~/components/BaseSelect/BaseSelect'
import CheckoutShort from '~/components/MarketplaceComponents/CheckoutShort'
import FormInput from '~/components/FormInput/FormInput'
import ChatService from '~/services/ChatService'
import ImageUpload from '~/components/ImageUpload/ImageUpload'
import StoreService from '~/services/StoreService'
import UsersService from '~/services/UsersService'

export default {
  name: 'ChatApp',

  components: {
    ImageUpload,
    BaseSelect,
    FormInput,
    CheckoutShort
  },

  data () {
    return {
      chatWith: undefined,
      chatType: 'general',
      chatTypes: [
        {
          value: 'general',
          title: this.$t('generalChat')
        }
      ],
      messages: [],
      socket: undefined,
      text: '',
      uploadedFile: undefined,
      menuIsOpen: false,
      disableButtons: false,
      chats: [],
      userNames: undefined,
      product: undefined,
      interval: undefined,
      productData: false,
      showButton: true,
      payMethod: '',
      payMethods: [
        {
          title: `${this.$t('creditCard')}`,
          commissionText: '3.3% +0.25€',
          value: 'BANKCARD',
          commission: 3.3,
          commissionFix: 25,
          icon: 'usd-card',
          euOnly: true
        },
        {
          title: `${this.$t('ruBankCard')}`,
          commissionText: '4%',
          value: 'CONTACTPAY', // this.isAdmin ? 'CONTACTPAY' : 'YOURPAYMENT', // P2P
          commission: 4,
          commissionFix: 0,
          icon: 'ru-card',
          ruOnly: true
        },
        {
          title: this.$t('siteBalance'),
          commissionText: '0%',
          value: 'BALANCE',
          commission: 0,
          commissionFix: 0,
          icon: 'V-round'
        }
      ]
    }
  },

  computed: {
    ...mapState('user', ['user', 'orders', 'sales']),
    ...mapState('chat', ['chatId', 'chatName', 'chatProductId']),

    chatWithOnlineText () {
      if (!this.chatWith?.last_api_call_at) {
        return undefined
      }

      const lastCall = this.$dateFns.parseISO(this.chatWith.last_api_call_at.replace(' ', 'T') + 'Z')

      const diff = this.$dateFns.differenceInMinutes(
        new Date(),
        lastCall
      )

      if (diff < 30) {
        return 'sellerTextOnline'
      }

      if (diff < 60) {
        return 'sellerTextHour'
      }

      if (this.$dateFns.isToday(lastCall)) {
        return 'sellerTextToday'
      }

      if (this.$dateFns.isYesterday(lastCall)) {
        return 'sellerTextYesterday'
      }

      return 'sellerTextNotOnline'
    },

    commission () {
      return (this.payMethods?.find(m => m.value === this.payMethod)?.commission || 0) / 100
    },

    commissionFix () {
      return this.payMethods.find(m => m.value === this.payMethod)?.commissionFix || 0
    },

    finalPriceWithCommission () {
      return (Math.ceil((this.product?.max_price) * (1 + this.commission) + this.commissionFix) / 100).toFixed(2)
    },

    totalCommission () {
      return (Math.ceil((this.product?.max_price) * (this.commission) + this.commissionFix) / 100).toFixed(2)
    },

    commissionText () {
      return this.payMethods.find(m => m.value === this.payMethod)?.commissionText || '...'
    },

    payMethodsToSelect () {
      return this.payMethods
        .filter(method => !method.ruOnly || this.$i18n.locale === 'ru')
        .filter(method => !method.euOnly || this.$i18n.locale !== 'ru')
        .map((m) => {
          const commission = m.commission / 100
          const commissionFix = m.commissionFix
          const finalPriceWithCommission = (Math.ceil((this.product?.max_price) * (1 + commission) + commissionFix) / 100).toFixed(2)

          return {
            ...m,
            money: finalPriceWithCommission
          }
        })
    },

    socialLink () {
      return this.user.social_network?.some(({ provider }) => provider === 'telegram')
    },

    chatTitle () {
      if (this.chatName === '' || this.chatId === 'ALL') {
        return this.$t('chat')
      }

      return this.chatName
    },

    orderId () {
      return this.chatId?.split('_')?.slice(-1)?.[0]
    },

    isOrderChat () {
      return this.chatId?.startsWith('marketplace_order_')
    },

    order () {
      return [...(this.orders || []), ...(this.sales || [])]?.find(o => +o.id === +this.orderId)
    },

    productTitle () {
      return this.order?.content?.items?.[0]?.title
    },

    productId () {
      return this.order?.content?.items?.[0]?.id
    },

    productAmount () {
      return this.order?.content?.items?.[0]?.amount || 1
    },

    productImage () {
      return this.product?.images?.[0]
    },

    isClient () {
      if (this.chatId === 'ALL' || !this.chatId.startsWith('marketplace_order_')) {
        return false
      }

      return this.orders?.find(order => +order.id === +this.orderId)?.account_id === this.user?.id
    }
  },

  watch: {
    chatId () {
      this.messages = []
      this.productData = false
      if (this.chatId === 'ALL') {
        this.loadAllChats()
        clearInterval(this.interval)
        this.interval = setInterval(this.loadAllChats, 60000)
      } else if (this.chatId.startsWith('marketplace_order_')) {
        const newChatId = this.chatId.replace('marketplace_order_', '').split('_').slice(0, 2).join('_')
        this.setChatId(newChatId)
      } else {
        this.loadChatMessages()
        this.loadChatWith()
        clearInterval(this.interval)
        this.interval = setInterval(this.loadChatMessages, 15000)
        this.loadProduct()
      }
    },

    uploadedFile () {
      if (this.uploadedFile) {
        this.sendMessage('file', this.uploadedFile)
      }
    }
  },

  async mounted () {
    await this.fetchUser()
    if (this.chatId === 'ALL') {
      this.loadAllChats()
      this.interval = setInterval(this.loadAllChats, 60000)
    } else {
      this.loadChatMessages()
      this.interval = setInterval(this.loadChatMessages, 15000)
      this.loadChatWith()
    }
    // this.socketConnect()
    setTimeout(this.scrollToBottom, 1000)

    if (!this.orders?.length) {
      this.fetchOrders()
    }

    if (!this.sales?.length) {
      this.fetchOrdersAsSeller()
    }

    this.loadProduct()
  },

  beforeDestroy () {
    this.socket?.disconnect?.(true)
    clearInterval(this.interval)
  },

  methods: {
    ...mapMutations('chat', ['setChatId', 'setChatName', 'setChatProductId']),
    ...mapActions('user', ['fetchOrders', 'fetchUser', 'fetchOrdersAsSeller']),
    ...mapActions('modal', ['showModal']),

    messageOrderId (message) {
      return message?.text?.replace('}', '')?.split('id:')?.[1]?.split(';;')?.[0]
    },

    messageProductId (message) {
      return message?.text?.replace(']]]', ';;')?.split('[[[product:')?.[1]?.split(';;')?.[0]
    },

    messageProductName (message) {
      if (this.messageProductId(message)) {
        return message?.text?.replace(']]]', ';;')?.split('[[[product:')?.[1]?.split(';;')?.[1]
      }
      return undefined
    },

    async loadProduct () {
      if (this.$route.path.includes('/marketplace/product/')) {
        this.setChatProductId(this.$route.params.id)
      }
      if (this.isOrderChat) {
        this.product = undefined
        this.product = (await StoreService.getMarketplace(this.$axios, this.productId))?.data?.data
      } else if (this.chatProductId) {
        this.product = (await StoreService.getMarketplace(this.$axios, this.chatProductId))?.data?.data
      }
    },

    scrollToBottom () {
      if (this.chatId !== 'ALL') {
        this.$refs.messagesBody.scrollTop = this.$refs.messagesBody.scrollHeight
      }
    },

    chatTitleByChat (chat) {
      if (chat.chat_id.startsWith('marketplace_order_')) {
        const orderId = +(chat?.chat_id?.split('_')?.slice(-1)?.[0] || 0)
        return `${this.$t('order')} #${orderId}`
      } else {
        const chatWith = +(chat?.chat_id?.split('_')?.filter(id => +id !== this.user?.id)?.[0] || 0)
        return this.userNames?.find(u => u.id === chatWith)?.full_name || chatWith || 'You'
      }
    },

    chatAvatar (chat) {
      if (chat.chat_id.startsWith('marketplace_order_')) {
        return undefined
      } else {
        const chatWith = +(chat?.chat_id?.split('_')?.filter(id => +id !== this.user?.id)?.[0] || 0)
        return this.userNames?.find(u => u.id === chatWith)?.avatar
      }
    },

    openChat (chat) {
      this.setChatName(this.chatTitleByChat(chat))
      this.setChatId(chat.chat_id)
      // this.$gtag.event('chat_click', { account_id: this.user?.id, place: 'in-chat' })
    },

    redirectSocialLink () {
      this.$router.push(this.localeLocation('/profile/notifications'))
      this.close()
    },

    async loadChatWith () {
      try {
        this.chatWith = undefined
        const chatWithId = this.chatId.replace(this.user?.id?.toString(), '').replace('_', '')
        this.chatWith = (await UsersService.getUserById(this.$axios, chatWithId))?.data?.data
      } catch (e) {
        console.log(e)
      }
    },

    async loadChatMessages () {
      const newMessages = (await ChatService.getChatMessages(this.$axios, this.chatId, this.messages?.filter?.(m => m.created_at)?.slice?.(-1)?.[0]?.id))?.data || []
      if (this.messages) {
        this.messages = this.messages.filter(m => m.created_at || !newMessages.map(nm => nm.text).includes(m.text))
        this.messages.push(...newMessages)
      } else {
        this.messages = newMessages
      }

      if (newMessages?.length) {
        this.fetchOrdersAsSeller()
        this.fetchOrders()
      }

      setTimeout(this.scrollToBottom, 1000)
      this.readMessages()
    },

    async loadChatUserToDisplayNames (userIds) {
      this.userNames = (await UsersService.getUsersNamesByIdList(this.$axios, userIds))?.data?.data || []
    },

    async loadAllChats () {
      this.chats = (await ChatService.getAllChats(this.$axios, this.user?.id))?.data || []

      if (this.chats?.length) {
        const users = this.chats
          .filter(chat => !chat.chat_id.startsWith('marketplace_order_'))
          .map(chat => chat.chat_id.split('_')
            .filter(part => +part && +part !== this.user?.id)
          )
          .reduce((a, b) => [...a, ...b], [])

        this.loadChatUserToDisplayNames(users)
      }
    },

    readMessages () {
      if (this.messages?.length) {
        ChatService.readMessage(this.$axios, this.user?.id, this.chatId, this.messages?.slice(-1)?.[0]?.id)
      }
    },

    switchMenu () {
      this.menuIsOpen = !this.menuIsOpen
    },

    chatClickAttachEvent () {
      // this.$gtag.event('chat_click_attach', { account_id: this.user?.id, chat_id: this.chatId })
    },

    socketConnect () {
      const socketUrl = this.$config.VUE_APP_CHAT_ADDR
      this.socket = io(socketUrl)

      this.socket.on('connection', (socket) => {
        console.log('socket', socket)
      })

      this.socket.on('connect_error', (err) => {
        console.log(`connect_error due to ${err.message}`)
      })

      this.socket.on('msg', (socket) => {
        const message = JSON.parse(socket)
        if (message.chat_id === this.chatId) {
          this.messages.push(message)
          setTimeout(() => {
            this.scrollToBottom()
            this.readMessages()
          }, 500)
        }
      })
    },

    async sendMessage (type = 'text', file = null) {
      const recipientAccountId = this.chatId === `${this.user?.id}_${this.user?.id}`
        ? this.user?.id
        : this.chatId.split('_').filter(part => +part && +part !== this.user?.id)

      let pId = this.product?.id
      let pTitle = this.product?.title

      if (!this.isOrderChat && !pId && this.$route.path.includes('/marketplace/product/')) {
        pId = this.$route.params.id
        pTitle = 'Unknown'
      }

      try {
        ChatService.sendNotification(this.$axios, +recipientAccountId[0], +this.chatId.split('_')[4], pId)
      } catch (e) {
        console.log(e)
      }

      try {
        if (type === 'file' && file) {
          // this.socket.emit('msg', JSON.stringify({
          //   text: `[file:${file}]`,
          //   account_id: this.user?.id,
          //   chat_id: this.chatId,
          //   user_name: this.user?.full_name
          // }))
          const message = {
            text: `[file:${file}]`,
            account_id: this.user?.id,
            chat_id: this.chatId,
            user_name: this.user?.full_name
          }
          this.messages = [...this.messages, message]
          this.uploadedFile = null
          await ChatService.sendMessage(this.$axios, this.chatId, message)
        } else {
          // this.socket.emit('msg', JSON.stringify({
          //   text: this.text,
          //   account_id: this.user?.id,
          //   chat_id: this.chatId,
          //   user_name: this.user?.full_name
          // }))
          const addPID = pId && !this.isOrderChat && this.user?.id !== this.product?.id
          const message = {
            text: addPID ? `[[[product:${pId};;${pTitle}]]]${this.text}` : this.text,
            account_id: this.user?.id,
            chat_id: this.chatId,
            user_name: this.user?.full_name
          }

          const firstMessage = addPID && (!this.messages?.length) ? {
            text: '[$t]orderFirstMessage',
            account_id: 0,
            chat_id: this.chatId,
            user_name: 'Bot'
          } : undefined

          this.messages = [...this.messages, message]

          this.text = ''

          await ChatService.sendMessage(this.$axios, this.chatId, message)

          if (firstMessage) {
            await ChatService.sendMessage(this.$axios, this.chatId, firstMessage)
          }
        }
        this.loadChatMessages()
      } catch (e) {
        console.log('SOCKET ERROR:', e)
      }
    },

    isMyMessage (message) {
      return this.user?.id === message.account_id
    },

    isOrderMessage (message) {
      return message.text.startsWith('[$t]')
    },

    isFile (text) {
      return text.startsWith('[file:http')
    },

    isImgFile (text) {
      return this.isFile(text) && (
        text.endsWith('.png]') ||
        text.endsWith('.jpg]') ||
        text.endsWith('.jpeg]')
      )
    },

    fileUrl (text) {
      return text.replace(/\[file:(.*)\]/, '$1')
    },

    fileName (text) {
      // eslint-disable-next-line no-useless-escape
      return text.replace(/\[file:(.*)\/([^\.]*).(.*)\]/, '$2')
    },

    messageText (message) {
      message.text = message.text
        .replace(/</g, '&lt;')
        .replace(/>/g, '&gt;')
      const toAdd = this.myRoleIsClient(message) ? 'ForClient' : 'ForSeller'
      if (message.text.startsWith('[$t]')) {
        let m = message.text.replace('[$t]', '')
        let p = {}
        if (m.endsWith('}')) {
          p = m.split('{')?.[1]?.replace('}', '')?.split(';;')?.map((e) => {
            const values = e.replace(':', '::::::::::::').split('::::::::::::')
            return {
              [values[0]]: values[1].replace(/\/n/g, '<br />')
            }
          }).reduce((a, b) => ({ ...a, ...b }), {})
          m = m.split('{')[0]
        }
        return this.$t(m + toAdd, p).replace('[USERNAME]', this.user?.full_name)
      } else {
        return message.text.replace(/\[\[\[product:.*\]\]\]/g, '')
      }
    },

    showButtons (message) {
      if (this.disableButtons) {
        return false
      }

      return !!message.text.startsWith('[$t]')
    },

    myRoleIsClient (message) {
      const orderId = message.text.replace('}', '').split('id:')[1]
      return this.orders?.find(order => +order.id === +orderId)
    },

    orderById (id) {
      return [...(this.orders || []), ...(this.sales || [])]?.find(order => +order.id === +id)
    },

    productByOrderId (orderId) {
      const order = this.orderById(orderId)
      return order?.content?.items?.[0]
    },

    orderItems (message) {
      const orderId = message.text.replace('}', '').split('id:')[1]
      const order = this.orderById(orderId)
      return order?.content?.items
    },

    messageProductTitle (orderId) {
      return this.productByOrderId(orderId)?.title?.replace(/^\[[^\]]*\] (.*)$/g, '$1')
    },

    messageProductAmount (orderId) {
      return this.productByOrderId(orderId)?.amount || 1
    },

    messageProductImage (orderId) {
      return this.productByOrderId(orderId)?.images?.[0]
    },

    orderCompleted (message) {
      const orderId = this.messageOrderId(message)
      return ['completed', 'completed_with_review', 'cancelled', 'cancelled_with_review'].includes(this.orderById(orderId)?.status)
    },

    orderCompletedButNotReviewed (message) {
      const orderId = this.messageOrderId(message)
      return ['completed'].includes(this.orderById(orderId)?.status)
    },

    orderCanceledButNotReviewed (message) {
      const orderId = this.messageOrderId(message)
      return ['cancelled'].includes(this.orderById(orderId)?.status)
    },

    orderStatusIs (message, status) {
      const orderId = this.messageOrderId(message)
      return status === this.orderById(orderId)?.status
    },

    orderNotCompleted (message) {
      return !this.orderCompleted(message)
    },

    orderNotPaid (message) {
      const orderId = this.messageOrderId(message)
      return ['created'].includes(this.orderById(orderId)?.status)
    },

    orderPaid (message) {
      const orderId = this.messageOrderId(message)
      return !(['created'].includes(this.orderById(orderId)?.status))
    },

    orderPaidButNotFulfilled (message) {
      const orderId = this.messageOrderId(message)
      return (['payed', 'paid'].includes(this.orderById(orderId)?.status))
    },

    async completeOrder (orderId) {
      this.menuIsOpen = false
      if (this.disableButtons) {
        return
      }
      this.disableButtons = true
      await StoreService.completeVirtualOrder(this.$axios, orderId)
      // window?.ym?.(96913300, 'reachGoal', 'bought', { order_price: +((this.orderById(orderId)?.content?.total || 0) / 100).toFixed(2), currency: 'USD' })
      setTimeout(window.location.reload, 8000)
      // this.disableButtons = false
    },

    async fulfillOrder (orderId) {
      this.menuIsOpen = false
      if (this.disableButtons) {
        return
      }
      this.disableButtons = true
      await StoreService.fulfillVirtualOrder(this.$axios, orderId)
      this.disableButtons = false
    },

    async progressOrder (orderId) {
      this.menuIsOpen = false
      if (this.disableButtons) {
        return
      }
      this.disableButtons = true
      await StoreService.progressVirtualOrder(this.$axios, orderId)
      this.disableButtons = false
    },

    async cancelOrder (orderId) {
      this.menuIsOpen = false
      if (this.disableButtons) {
        return
      }
      this.disableButtons = true
      await StoreService.cancelVirtualOrder(this.$axios, orderId)
      window.location.reload()
      this.disableButtons = false
    },

    async buyerCancelOrder (orderId) {
      this.menuIsOpen = false
      if (this.disableButtons) {
        return
      }
      this.disableButtons = true
      await StoreService.buyerCancelVirtualOrder(this.$axios, orderId)
      window.location.reload()
      this.disableButtons = false
    },

    leaveFeedback (orderId, sellerId) {
      this.showModal({
        component: 'ModalLeaveFeedback',
        data: {
          orderId: +orderId,
          sellerId: +sellerId
        }
      })
    },

    openPaymentBlock (orderId) {
      // this.productData = true
      this.$router.push(this.localeLocation(`/marketplace/order/${orderId}`))
      this.close()
    },

    async pay () {
      this.productData = false
      const response = await StoreService.payForOrder(this.$axios, this.orderId, this.payMethod, `${location.protocol}//${location.host}/profile/orders`)

      const url = response?.data?.data?.returnUrl
      if (url === '') {
        this.fetchOrders()
      } else if (url) {
        window.location.replace(url)
      }
    },

    messageButtons (message) {
      const orderId = this.messageOrderId(message)

      if (this.myRoleIsClient(message)) {
        if (message.text.startsWith('[$t]orderPayed') && this.orderStatusIs(message, 'paid')) {
          return [
            {
              id: 'cancelOrderBtn',
              text: this.$t('cancelOrder'),
              action: () => this.buyerCancelOrder(orderId)
            }
          ]
        } else if (message.text.startsWith('[$t]orderCompleted') && this.orderStatusIs(message, 'completed')) {
          return [{
            id: 'reviewOrderBtn',
            text: this.$t('reviewOrder'),
            action: () => this.leaveFeedback(orderId, this.orderById(orderId)?.seller_id)
          }]
        } else if (message.text.startsWith('[$t]orderCancel') && this.orderStatusIs(message, 'cancelled')) {
          return [{
            id: 'reviewOrderBtn',
            text: this.$t('reviewOrder'),
            action: () => this.leaveFeedback(orderId, this.orderById(orderId)?.seller_id)
          }]
        } else if (message.text.startsWith('[$t]newOrderCreated') && this.orderStatusIs(message, 'created')) {
          return []
        } else if (message.text.startsWith('[$t]orderInProgress') && this.orderStatusIs(message, 'in_progress')) {
          return []
        } else if (message.text.startsWith('[$t]orderFulfilled') && this.orderStatusIs(message, 'fulfilled')) {
          return [{
            id: 'completeOrderBtn',
            text: this.$t('completeOrder'),
            action: () => this.completeOrder(orderId)
          }]
        }
      } else if (message.text.startsWith('[$t]orderPayed') && this.orderStatusIs(message, 'paid')) {
        return [
          {
            id: 'progressOrderBtn',
            text: this.$t('progressOrder'),
            action: () => this.progressOrder(orderId)
          },
          {
            id: 'cancelOrderBtn',
            text: this.$t('cancelOrder'),
            action: () => this.cancelOrder(orderId)
          }
        ]
      } else if (message.text.startsWith('[$t]orderInProgress') && this.orderStatusIs(message, 'in_progress')) {
        return [
          {
            id: 'fulfillOrderBtn',
            text: this.$t('fulfillOrder'),
            action: () => this.fulfillOrder(orderId)
          },
          {
            id: 'cancelOrderBtn',
            text: this.$t('cancelOrder'),
            action: () => this.cancelOrder(orderId)
          }
        ]
      }

      return []
    },

    labelText (message) {
      if (message.account_id === 0) {
        return 'notification'
      }
    },

    created (message) {
      if (!message.created_at) {
        return ''
      }
      return this.$dateFns.format(message.created_at, 'dd.MM.yyyy hh:mm')
    },

    close () {
      this.setChatId(null)
      // this.$gtag.event('chat_close', { account_id: this.user?.id, place: 'in-chat' })
    }
  }
}
</script>

<style lang="scss" scoped>
.chat-app {
  position: fixed;
  --header-height: 1px;
  top: var(--header-height);
  right: 0;
  display: grid;
  grid-template-rows: max-content 1fr max-content max-content;
  width: 100%;
  border-left: 1px solid $blue-grey30;
  height: 100vh;
  max-height: 100%;
  max-height: -moz-available;
  max-height: -webkit-fill-available;
  max-height: fill-available;
  background: $blue-grey80;
  z-index: 1000;

  @media (min-width: $breakpointDesktopMedium) {
    max-width: 500px;
    --header-height: 1px;
    padding-top: 120px;
    z-index: 2;
  }

  &--with-product {
    grid-template-rows: max-content max-content 1fr max-content 1fr;
  }

  &__product {
    @include silent-scroll;

    min-height: 30vh;
    max-height: 50vh;
    padding: 0 16px 16px;
    overflow-y: auto;
  }

  &__header {
    position: relative;
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 12px 16px;
    border-bottom: 1px solid $blue-grey40;
    border-top: 1px solid $blue-grey40;

    &-title {
      width: 100%;
      font-weight: 700;
      font-size: 20px;
      line-height: 28px;
      color: $white;

      &-status {
        font-size: 16px;
        font-weight: 500;
        line-height: 20px;
        color: $white;

        &__sellerTextNotOnline {
          color: $blue-grey10;
        }

        &__sellerTextOnline {
          color: $green60;
        }

        &__sellerTextHour {
          color: $yellow60;
        }

        &__sellerTextToday {
          color: $orange60;
        }

        &__sellerTextYesterday {
          color: $pink60;
        }
      }
    }

    &-back {
      display: flex;
      align-items: center;
      justify-content: center;
      width: 32px;
      height: 32px;
      cursor: pointer;
      margin-right: 16px;
    }

    &-icons {
      display: flex;
      align-items: center;
      justify-content: flex-end;
    }

    &-close {
      display: flex;
      align-items: center;
      justify-content: center;
      width: 32px;
      background: $blue-grey60;
      padding: 6px;
      border-radius: 50%;
      cursor: pointer;
      margin-left: 16px;

      img {
        width: 16px;
        height: 16px;
      }
    }

    &-dots {
      width: 24px;
      height: 24px;
      cursor: pointer;

      ::v-deep path {
        fill: $white;
      }
    }

    &-menu {
      position: absolute;
      right: 16px;
      top: 60px;
      padding: 8px;
      border-radius: 8px;
      border: 1px solid $blue-grey40;
      background: $blue-grey70;

      &-item {
        padding: 12px;
        font-size: 14px;
        font-weight: 500;
        line-height: 18px;
        display: flex;
        align-items: center;
        justify-content: flex-start;
        cursor: pointer;

        &--success {
          color: $green60;
        }

        &--red {
          color: $pink60;
        }

        &-icon {
          width: 20px;
          height: 20px;
          margin-right: 8px;
        }
      }
    }
  }

  &__order {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    padding: 12px 0;
    border-bottom: 1px solid $blue-grey40;
    height: max-content;

    &-number {
      display: inline-block;
      margin-right: 12px;
      padding: 4px 8px;
      background: $primary60;
      color: $white;
      font-weight: 700;
      font-size: 12px;
      line-height: 100%;
      border-radius: 8px;
    }

    &-img {
      width: 32px;
      height: 32px;
      border-radius: 4px;
      object-fit: cover;
      margin-right: 12px;
    }

    &-title {
      font-weight: 500;
      font-size: 16px;
      line-height: 20px;
      color: $blue-grey10;
      text-decoration: underline;
    }

    &-amount {
      font-weight: 700;
      font-size: 16px;
      line-height: 20px;
      color: $amber60;
      margin-left: 8px;
    }
  }

  &__messages {
    @include scroll;
    width: 100%;
    padding: 16px;
    overflow: hidden;

    &-body {
      display: grid;
      grid-gap: 8px;
      overflow-y: auto;
      max-height: 100%;

      &::-webkit-scrollbar {
        width: 5px;
      }

      /* Track */
      &::-webkit-scrollbar-track {
        background: $blue-grey80;
      }

      /* Handle */
      &::-webkit-scrollbar-thumb {
        background: $blue-grey60;
        border-radius: 4px;
      }

      /* Handle on hover */
      &::-webkit-scrollbar-thumb:hover {
        background: $blue-grey05;
      }
    }
  }

  &__chats {
    @include scroll;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
    padding: 16px;
    overflow-y: auto;
    padding-bottom: 40px;

    &-chat {
      display: grid;
      grid-template-columns: 48px 1fr 23px;
      grid-gap: 12px;
      align-items: center;
      width: 100%;
      padding: 12px;
      margin-bottom: 8px;
      border-radius: 8px;
      background: $blue-grey60;
      color: $white;
      font-size: 16px;
      font-weight: 500;
      line-height: 20px;
      height: max-content;
      cursor: pointer;

      &--support {
        background: $primary60-10;
        border: 1px solid $primary60;
      }

      &-cover {
        width: 48px;
        height: 48px;
        background: $blue-grey70;
        border-radius: 50%;
        padding: 2px;

        &--support {
          background: $primary60;
          padding: 12px;
        }

        &-img {
          width: 100%;
          height: 100%;
          object-fit: cover;
          border-radius: 50%;

          &--support {
            ::v-deep path {
              fill: $white;
            }
          }
        }
      }

      &-title {
        color: $white;
        font-size: 16px;
        font-weight: 500;
        line-height: 20px;
      }

      &-counter {
        display: flex;
        align-items: center;
        justify-content: center;
        border-radius: 50%;
        height: 23px;
        width: 23px;
        background: $primary60;
        color: $white;
        font-size: 12px;
        font-weight: 500;
        line-height: 15px;
      }
    }
  }

  &__message {
    display: grid;
    grid-template-columns: 1fr;
    grid-gap: 6px;
    align-items: flex-start;
    background: $blue-grey60;
    padding: 12px 16px;
    width: 85%;
    margin-left: 9px;
    border-radius: 12px 12px 12px 0;

    &--my {
      border-radius: 12px 12px 0 12px;
      margin-right: 9px;
      margin-left: calc(15% - 9px);
      background: rgba($cyan60, .2);
    }

    &-user {
      display: flex;
      align-items: center;
      justify-content: flex-start;
      font-weight: 500;
      font-size: 12px;
      line-height: 14px;
      color: $orange60;
      overflow: hidden;
      text-overflow: ellipsis;

      &--my {
        color: $cyan60;
      }

      &--system {
        color: $primary60;
      }

      &-label {
        padding: 0 4px;
        margin-left: 8px;
        font-size: 14px;
        font-weight: 500;
        line-height: 20px;
        border-radius: 2px;

        &--system {
          background: $primary60;
          color: $white;
        }
      }
    }

    &-text {
      font-weight: 400;
      font-size: 12px;
      line-height: 16px;
      color: $white;
    }

    &-info {
      display: block;
      margin: 12px 0 0;
      padding: 8px 16px;
      background: $primary60-20;
      color: $white;
      font-size: 14px;
      font-weight: 400;
      line-height: 120%;
      border-radius: 8px;

      &-item {
        color: $primary60;
        font-weight: 700;
      }
    }

    &-img {
      width: 80%;
      max-height: 200px;

      img {
        width: 100%;
        height: 200px;
        object-fit: contain;
      }
    }

    &-created {
      color: rgba($white, .5);
      font-size: 12px;
      font-weight: 500;
      line-height: 14px;
      text-align: right;
    }

    &-buttons {
      margin: 12px 0 6px;
      display: flex;
      align-items: center;
      flex-wrap: wrap;
    }

    &-button {
      margin: 2px;
    }
  }

  &__send {
    background: $blue-grey90;

    &-group {
      display: grid;
      grid-template-columns: 1fr 42px;
      grid-gap: 8px;
      align-items: center;
      background: $blue-grey90;
      padding: 12px 16px;
    }
  }

  &__upload-icon {
    margin: auto;
    cursor: pointer;
  }
}
</style>
