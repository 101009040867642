<template>
  <div :class="{'file-upload': true, '--error': error}">
    <label class="file-upload__label">
      <input ref="input" type="file" accept="image/png, image/jpeg, image/jpg" @change="onImageChange($event)" />
      <span v-if="value && !short">
        {{ value }}
      </span>
      <span v-else-if="!short" class="file-upload__placeholder">
        {{ placeholder }}
      </span>
      <slot name="button">
        <Button is-secondary>
          {{ $t(buttonText) }}
        </Button>
      </slot>
      <div v-if="error && !short" class="form-input__error">
        {{ error }}
      </div>
    </label>
  </div>
</template>

<script>
import FileUpload from '~/components/FileUpload/FileUpload'

export default {
  name: 'ImageUpload',

  extends: FileUpload,

  props: {
    buttonText: {
      type: String,
      default: 'buttonChooseFile'
    },

    short: {
      type: Boolean,
      default: false
    }
  },

  methods: {
    clickInput () {
      this.$refs.input.click()
    },

    async onImageChange (event) {
      const file = event.target

      const form = new FormData()
      form.append('image', file.files[0])

      console.log('image upload', file, file.files)

      if (file.files[0]) {
        this.$nuxt.$loading.start()

        try {
          const { data } = await this.$axios.post('/user/upload', form)

          this.$emit('input', data.data)
        } catch (e) {
          this.$errors.handle(e)
          console.error(
            `${new Date().toUTCString()} :: image upload error ::`,
            e
          )
        }

        this.$nuxt.$loading.finish()
      }

      this.$emit('blur')
    }
  }
}
</script>
